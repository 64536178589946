import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from "../components/atoms/SEO/SEO";
import Section from "../components/organisms/Section/Section";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";

const Privacy = () => (
  <>
    <CartPage />
    <Layout>
      <SEO
        title="Datenschutzerklärung"
        description="Informationen darüber, welche Daten ich erhebe und wie ich diese verwende"
      />
      <Section>
        <PageWidth>
          <h1>Daten&shy;schutz&shy;erklärung</h1>
          <p>
            Hier erläutere ich kurz, welche Daten ich beim Besuch meiner Website
            erhebe und wie diese verwendet werden.
          </p>
          <h2>Umfang der Datenerhebung, -verarbeitung und -nutzung</h2>
          <p>
            Du kannst diese Seite besuchen und alle Inhalte abrufen, ohne dass
            ich selbst personenbezogene Daten von Dir erhebe, oder Du Angaben zu
            personenbezogenen Daten machen musst.
          </p>
          <h3>Hosting</h3>
          <p>
            Das Hosting wird von{" "}
            <a
              href="https://www.netlify.com/gdpr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Netlify
            </a>{" "}
            durchgeführt. Dort werden personenbezogene Daten in Form von Logs
            (IP-Adresse) erhoben und maximal 30 Tage gespeichert.
          </p>
          <h3>Kontaktaufnahme per E-Mail</h3>
          <p>
            Wenn Du mit mir per E-Mail Kontakt aufnimmst, wird Deine E-Mail
            einschließlich darin enthaltener personenbezogener Daten (wie z.B.
            Name und Anschrift) auf meinem E-Mail Server gespeichert. Alte
            E-Mails werden regelmäßig gelöscht.
          </p>
          <h3>Newsletter</h3>
          <p>
            Der Newsletter wird in meinem Auftrag von{" "}
            <a
              href="https://mailchimp.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mailchimp
            </a>{" "}
            realisiert. Ich speichere dort Deine E-Mail Adresse, Namen und die
            Seite auf der Du Dich für den Newsletter angemeldet hast. Mailchimp
            speichert selbst auch Daten/Cookies, um Nutzungsstatistiken
            bereitzustellen. Du kannst dort auch selbst Deine Daten ändern oder
            löschen. Bitte benutze dafür den Link innerhalb des Newsletters.
          </p>
          <h3>Einkauf</h3>
          <p>
            Ich verwende für die Abwicklung der Zahlung Deines Einkaufs{" "}
            <a
              href="https://stripe.com/de/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </a>
            . Dort werden Deine personenbezogenen Daten gespeichert. Ich habe
            keinen Zugriff auf Deine Bank- oder Kartendetails. Stripe führt in
            meinem Auftrag die Zahlung durch. Es wird kein Kundenkonto für Dich
            angelegt. Es werden nur Einmalzahlungen verarbeitet.
          </p>
          <p>
            Ich verwende{" "}
            <a
              href="https://support.airtable.com/hc/en-us/articles/360003938973"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airtable
            </a>{" "}
            für die Verwaltung Deiner Bestellung. Dort werden Deine
            personenbezogenen Daten (Name, E-Mail, Anschrift) ebenfalls
            gespeichert. Die Daten werden für die steuerliche Abrechnung nur
            solange aufbewahrt, wie vorgeschrieben.
          </p>
          <p>
            Ich versende E-Mails für die Abwicklung Deiner Bestellung mit Hilfe
            von{" "}
            <a
              href="https://www.mailgun.com/gdpr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mailgun
            </a>
            . Dort werden Deine Daten (Inhalt der E-Mail) nur wenige Tage
            vorgehalten, damit bei misslungener Zustellung ein weiterer Versucht
            unternommen werden kann.
          </p>
          <p>
            Der Versand erfolgt über DHL. Deine Anschrift wird zu diesem Zweck
            an DHL übermittelt.
          </p>
          <p>
            Damit ich Deine Bestellung abwickeln kann, werden alle Daten, die Du
            dabei eingibst (außer Zahlungsinformationen) von mir gespeichert.
            Ich lösche die Daten alter Bestellungen regelmäßig, wenn diese nicht
            mehr benötigt werden.
          </p>
          <h2>Keine Pflicht zur Bereitstellung von Daten</h2>
          <p>
            Du bist weder gesetzlich, noch vertraglich verpflichtet, mir Daten
            beim Besuch meiner Internetseite zur Verfügung zu stellen. Für die
            Nutzung unserer Website ist es jedoch erforderlich, dass Du mir
            diejenigen Daten mitteilst, die für die Erbringung dieser Leistungen
            (Besuch) benötigt werden. Ohne diese Daten ist eine Nutzung dieser
            Leistungen technisch nicht möglich.
          </p>
          <h2>Dauer der Speicherung und Übermittlung</h2>
          <p>
            Deine Daten werden solange gespeichert, wie es für die Erfüllung
            Deiner Anfrage bzw. gem. gesetzlichen Fristen notwendig ist. Nicht
            benötigte Daten werden regelmäßig gelöscht.
          </p>
          <p>
            Deine Daten werden zur Durchführung Deiner Bestellung an Stripe und
            DHL übermittelt.
          </p>
          <h2>Sicherheit Deiner Daten</h2>
          <p>
            Ich treffe alle angemessenen technisch-organisatorische Maßnahmen,
            um den Schutz Deiner Daten zu gewährleisten.
          </p>
          <h2>Änderungen</h2>
          <p>
            Ich behalte mir vor, die Regelungen dieser Datenschutzerklärung von
            Zeit zu Zeit anzupassen. Ältere Fassungen der Datenschutzerklärung
            kannst Du per E-Mail bei mir anfordern.
          </p>
          <p className="small-print">Fassung 1.0 vom 7. Juli 2019</p>
        </PageWidth>
      </Section>
    </Layout>
  </>
);

export default Privacy;
