import React from "react";
import ShopContext from "../ShopContext";
import Cart from "../../../../assets/svg/Cart.svg";
import Button from "../../../atoms/Button/Button";

import "./CartPage.less";
import { MIN_CART_VALUE } from "../../../../const/cart";

const CartPage = props => {
  const { isBought } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const cart = React.useContext(ShopContext);

  let totalQuantity = 0;
  let totalPrice = 0;
  if (cart.products) {
    cart.products.forEach(x => {
      totalQuantity = totalQuantity + x.quantity || 0;
      totalPrice = totalPrice + x.quantity * x.price;
    });
  }

  React.useEffect(() => {
    if (cart.products.length > 0 && isBought === true) {
      cart.onEmpty();
    }
  }, [cart, isBought]);

  React.useEffect(() => {
    if (!cart.isTouched && totalQuantity > 0) {
      setIsOpen(true);
      cart.onTouched();
    }
  }, [cart, totalQuantity]);

  if (!cart) return null;

  const onToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const onBuy = () => {
    cart.onBuy();
  };

  const classNames = ["cart-page"];
  if (isOpen) {
    classNames.push("cart-page--open");
  }

  return (
    <div className={classNames.join(" ")}>
      <div
        role="button"
        tabIndex="-1"
        className="cart-page__icon"
        onClick={onToggleIsOpen}
        onKeyPress={onToggleIsOpen}
        title={isOpen ? "schließen" : "öffnen"}
      >
        <Cart />
        {totalQuantity > 0 && (
          <div className="cart-page__icon-badge">{totalQuantity}</div>
        )}
      </div>
      {isOpen && totalQuantity === 0 && (
        <div className="cart-page__empty">
          <div className="cart-page__empty-text">
            Dein Warenkorb ist noch leer.
          </div>
          <Button
            asLink={true}
            variant="secondary"
            onClick="/sorten"
            className="cart-page__button"
          >
            Honig aussuchen
          </Button>
        </div>
      )}
      {isOpen && totalQuantity > 0 && (
        <div className="cart-page__data">
          <div
            role="button"
            tabIndex="-1"
            className="cart-page__close"
            onClick={onToggleIsOpen}
            onKeyPress={onToggleIsOpen}
          >
            ausblenden
          </div>
          <div className="cart-page__list-header">In Deinem Warenkorb:</div>
          <ul className="cart-page__list">
            {cart.products.map(product => (
              <li className="cart-page__item" key={product.id}>
                <div className="cart-page__quantity">
                  {product.quantity}
                  <span className="cart-page__multiplier">x</span>
                </div>
                <div className="cart-page__product">{product.name}</div>
              </li>
            ))}
          </ul>
          <div className="cart-page__list-footer">
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "EUR"
            }).format(totalPrice)}
            <div className="cart-page__legal">
              inkl. Versand und MwSt.
              {totalPrice < MIN_CART_VALUE && (
                <>
                  <br />
                  Mindestbestellwert noch nicht erreicht
                </>
              )}
            </div>
          </div>
          <Button
            variant="secondary"
            onClick={onBuy}
            disabled={totalPrice < MIN_CART_VALUE}
            className="cart-page__button"
          >
            zur Kasse
          </Button>
        </div>
      )}
    </div>
  );
};

CartPage.defaultProps = {
  isBought: false
};

export default CartPage;
